body {
    margin: 0;
    font-family: "doge", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    margin: 0;
}

@font-face {
    font-family: "doge";
    font-display: swap;
    src: url("./fonts/DogeSansVF.ttf");
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes flop {
    from {
        font-variation-settings: "FLOP" 0;
    }
    to {
        font-variation-settings: "FLOP" 100;
    }
}

@property --num {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}
.counter {
    animation: counter 0.9s infinite alternate ease-in-out;
    counter-reset: num var(--num);
}

.counter::after {
    content: counter(num);
}

@keyframes counter {
    from {
        --num: 0;
    }
    to {
        --num: 99;
    }
}
